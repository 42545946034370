<template>
    <modal :show="showModal" size="lg" @close="onModalClose">
        <template v-slot:header>
            <h5 class="modal-title" id="modalTitle">{{ title }}</h5>
        </template>
        <div>
            <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <b-row>
                        <b-col sm="12">
                            <p class="text-primary"><strong>Customer Pending Fee</strong></p>
                            <hr class="mt-0 bg-primary">
                        </b-col>
                        <b-col sm="12">
                            <base-input
                                    v-model="meta.remark"
                                    type="text"
                                    name="remark"
                                    label="Remark">
                                </base-input>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </div>
        <template v-slot:footer>
            <base-button type="secondary" @click="showModal = false">Close</base-button>
            <base-button type="primary" @click="() => $emit('clearPendingFee', meta)">Pay</base-button>
        </template>
    </modal>
</template>
<script>
import { Select, Option } from 'element-ui';
export default {
    data() {
        return {
            title: '',
            showModal: false,
            meta: {
                remark: ''
            }
        }
    },
    methods: {
        onSubmit() {
            this.$emit('onSubmit', this.meta);
        },
        show({ remark }) {
            this.showModal = true;
            this.meta = {
                remark
            }
        },
        onModalClose() {
            this.hide();
        },
        hide() {
            this.showModal = false;
        },
    },
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
    }
}
</script>